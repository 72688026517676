import React from 'react'

// Components
import CtaDefault from 'components/flex/Cta/CtaDefault'
import CtaVoorwaarden from 'components/flex/Cta/CtaVoorwaarden'

interface CtaShellProps {
  // eslint-disable-next-line camelcase
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Cta
  location?: any
}

interface BannersProps {
  [key: string]: any
}

const CtaShell: React.FC<CtaShellProps> = ({ fields, location = {} }) => {
  const banners: BannersProps = {
    default: CtaDefault,
    voorwaarden: CtaVoorwaarden,
  }

  if (!fields.styletype || !banners[fields.styletype]) {
    return null
  }

  const Component = banners[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default CtaShell
