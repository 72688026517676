import React from 'react'
import styled from 'styled-components'

// Components
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'
import ButtonPrimaryBig from 'components/elements/Buttons/ButtonPrimaryBig'

const Cta = styled.div``

const Image = styled(Plaatjie)`
  width: 134px;
  height: 134px;
  border-radius: 50%;
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-size: 20px !important;
    font-family: ${({ theme }) => theme.font.family.primary};
    line-height: 20px;

    @media (max-width: 1299px){
      font-size: 18px !important;
    }
  }
  & p {
    font-size: 16px;
  }
`

interface CtaVoorwaardenProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Cta
}

const CtaVoorwaarden: React.FC<CtaVoorwaardenProps> = ({ fields }) => (
  <section className="mb-5">
    <Cta className="d-flex flex-column flex-xl-row align-items-start align-items-lg-end justify-content-between mt-5 mt-xl-0">
      <Image image={fields.image} alt="Borg OSC Recruiter" className="me-lg-3 me-xl-0" />
      <Content content={fields.call} />
      <div className="mb-2">
        <ButtonPrimaryBig to={fields.action?.url || '/'}>
          {fields.action?.title}
        </ButtonPrimaryBig>
      </div>
    </Cta>
  </section>
)

export default CtaVoorwaarden