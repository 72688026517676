import React from 'react'
import styled, { css } from 'styled-components'

// Components
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'
import ButtonPrimaryTransparent from 'components/elements/Buttons/ButtonPrimaryTransparent'

const Cta = styled.div``

const Image = styled(Plaatjie)`
  width: 134px;
  height: 134px;
  border-radius: 50%;
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-size: 20px !important;
    font-family: ${({ theme }) => theme.font.family.primary};
    line-height: 20px;

    @media (max-width: 1399px){
      font-size: 18px !important;
    }
  }
  & p {
    font-size: 16px;
  }
`

const StyledLink = styled.a`
   ${({ theme }) => css`
    background: ${theme.color.gradient.to};
    background: ${`rgb(${theme.color.gradient.to})`};
    background: ${`linear-gradient(90deg, rgba(${theme.color.gradient.from}, 1) 0%, rgba(${theme.color.gradient.to}, 1) 100%);`};
  `};
  display: inline-block;
  text-align: center;
  min-width: 220px;

  & > span
    color: ${(props) => props.theme.color.primary} !important;
    font-weight: ${(props) => props.theme.font.weight.semiBold} !important;
    padding: 7px 30px;
    width: 100%;
    height: 100%;
    display: block;
    text-decoration: none !important;
`

const ApplyDiv = styled.div`
  @media (max-width: 1199px){
    order: 2;
    margin-top: 1rem;
  }
`

interface CtaDefaultProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Cta
}

const CtaDefault: React.FC<CtaDefaultProps> = ({ fields }) => (
  <section className="mb-5">
    <Cta className="d-flex flex-column flex-xl-row align-items-lg-end justify-content-lg-center mt-5 mt-xl-0">
      <ApplyDiv className="me-lg-5">
        <StyledLink href="#apply" className="mb-2">
          <span>Kom kennismaken</span>
        </StyledLink>
      </ApplyDiv>
      <div className="d-flex align-items-end ms-lg-3">
        <div className="mb-2 me-4">
          <Content content={fields.call} className="mb-2" />
          <ButtonPrimaryTransparent to={fields.action?.url || '/'}>
            {fields.action?.title}
          </ButtonPrimaryTransparent>
        </div>
        <Image image={fields.image} alt="Borg OSC Recruiter" />
      </div>
    </Cta>
  </section>
)

export default CtaDefault
